import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import PlanDetail from '../views/PlanDetail.vue'
import PathNotFound from '../views/PathNotFound.vue'
import axios from 'axios'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PathNotFound',
    component: PathNotFound
  },
  {
    path: '/:category_slug/:product_slug',
    name: 'Product',
    component: PlanDetail
    /*
    beforeEnter: async (to, from, next) => {
      try {
        const categoryExists = await checkCategoryExists(to.params.category_slug);
        const productExists = await checkProductExists(to.params.category_slug, to.params.product_slug);

        if (categoryExists && productExists) {
          next();
        } else {
          next({ name: 'PathNotFound' });
        }
      } catch (error) {
        next({ name: 'PathNotFound' });
      }
    }*/
  },
  {
    path: '/:category_slug',
    name: 'Category',
    component: () => import('../views/Category.vue')
    /*
    beforeEnter: async (to, from, next) => {
      try {
        const categoryExists = await checkCategoryExists(to.params.category_slug);

        if (categoryExists) {
          next();
        } else {
          next({ name: 'PathNotFound', });
        }
      } catch (error) {
        next({ name: 'PathNotFound' });
      }
    }*/
  },
  {
    path: '/collections',
    name: 'Categories',
    component: () => import('../views/Categories.vue')
  },
  {
    path: '/plans-sort',
    name: 'PlanSort',
    component: () => import('../views/PlanSort.vue')
  },
  {
    path: '/favorites',
    name: 'FavoritesView',
    component: () => import('../views/FavoritesView.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/cart/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/cart/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue'),
    meta: {
        requireLogin: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/orders',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: () => import('../views/LogIn.vue')
  },
  {
    path: '/reset-email',
    name: 'ResetEmail',
    component: () => import('../views/ResetEmail.vue')
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetConfirm',
    component: () => import('../views/ResetConfirm.vue')
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } })
  } else {
    next()
  }
})

/*
async function checkCategoryExists(categorySlug) {
  try {
    const response = await axios.get(`/api/v1/plans/${categorySlug}/`);
    console.log(response.status)
    return response.status === 200;
    
  } catch (error) {
    return false;
  }
}

async function checkProductExists(categorySlug, productSlug) {
  try {
    const response = await axios.get(`/api/v1/plans/${categorySlug}/${productSlug}/`);
    return response.status === 200;
  } catch (error) {
    return false;
  }
}
*/
export default router
