<template>
    <section class="hero is-large mt-4 container">
      <div class="section pt-2 pb-2">
        <img fetchpriority="high" @load="onImageLoad" src="https://res.cloudinary.com/celestialmk/image/upload/v1701090063/s7ts2cyugsioxtqnmmve.webp" alt=""> 
      </div>
      <SearchFilter :imageLoaded="imageLoaded" />
    </section>

<section class="section pt-2 container">
<h1 v-if="stored_plans != ''" class="is-size-3 has-text-grey has-text-left">Welcome Back! Your recently viewed plans:</h1>

<vue-horizontal>

    <div v-for="item in stored_plans" :key="item.product.id">
      <div class="item">
        <router-link v-bind:to="item.product.get_absolute_url">
          <img v-bind:src="item.product.get_thumbnail" style="height: 200; width: 300; display: block; width:100%" alt="">
        <div class="" style="bottom: 0; width: 100%; background-color: rgba(0,0,0,.5);">
          <div class="has-text-white is-size-5">
            {{ item.product.name }}
              <!--<router-link v-bind:to="item.product.get_absolute_url">{{ item.product.name }}</router-link>-->
          </div>
        </div>
        </router-link>
      </div>
    </div>
  </vue-horizontal>
</section>

<section class="section pt-2 container">
  <h1 class="title">Why buy our plans</h1>
  <div class="columns">
    <div class="column is-one-third">
      
      <span><image loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716096305/yma17pxgyhfisteampei.png"></image></span>
      <h1 class="subtitle">Construction Experience</h1>
      <p>
        Backed by Eco Concrete Ltd, a leading Construction company in Uganda. We ensure feasibility of architectural plans sold.
      </p>
    </div>
    <div class="column is-one-third">
      <span><image loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716096305/dgwsntxolxt8vw3evspq.png"></image></span>
      <h1 class="subtitle">Curated Portfolio</h1>
      <p>
        Over 40+ completed projects marked with exceptional professionalism and positive feedback from our clients.
      </p>
    </div>
    <div class="column is-one-third">
      <span><image loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1716096305/irbbgtblaymgflerpai5.png"></image></span>
      <h1 class="subtitle">We give back</h1>
      <p>
        Fostering research in innovative ways of Construction using green cement to lower project carbon footprint.
      </p>
    </div>
  </div>
</section>


<section class="section pt-2 container">
  <h1 class="is-size-3 has-text-grey has-text-left">Home Plan Collections</h1>
  <div class="columns">
    <div class="column is-one-third">
      <router-link to="/villas">
        <img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1700818860/zrxsuuscne03vzmhh7qi.jpg" style="height: 200; width: 300; display: block; width:100%" alt="">
        <div class="" style="bottom: 0; width: 100%; background-color: rgba(0,0,0,.5);">
          <div class="has-text-white is-size-5">
            Modern
          </div>
        </div>
      </router-link>
    </div>
    <div class="column is-one-third">
      <router-link to="bungalows">
        <img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1700819687/qj5g7g1u1zpgygxpiktz.jpg" style="height: 200; width: 300; display: block; width:100%" alt="">
        <div class="" style="bottom: 0; width: 100%; background-color: rgba(0,0,0,.5);">
          <div class="has-text-white is-size-5">
            Bungalow
          </div>
        </div>
      </router-link>
    </div>
    <div class="column is-one-third">
      <router-link to="/apartments">
        <img loading="lazy" src="https://res.cloudinary.com/celestialmk/image/upload/v1700819828/u3zwsvdjfj3xve5at2y3.jpg" style="height: 200; width: 300; display: block; width:100%" alt="">
        <div class="" style="bottom: 0; width: 100%; background-color: rgba(0,0,0,.5);">
          <div class="has-text-white is-size-5">
            Apartment
          </div>
        </div>
      </router-link>
    </div>

  </div>
  <router-link to="/collections" class="has-text-right has-text-success">
    <p>Explore more Collections </p>
  </router-link>
</section>

<!--
  <section class="section is-medium pt-3 pb-2">
    <div class="columns is-multiline" v-if="latestProducts != ''">
        <div class="column is-12">
            <h2 class="title has-text-centered">Latest Building Plans</h2>
        </div>
          <ProductComponent
            v-for="product in latestProducts"
            v-bind:key="product.id"
            v-bind:product="product" />
        
    </div>
    <div v-else>
      <Loading />
    </div>
  </section>
-->


</template>

<script>
// @ is an alias to /src
import ProductComponent from '@/components/ProductComponent.vue';
import PlanFilter from '@/components/PlanFilter.vue';
import SearchFilter from '@/components/SearchFilter.vue';
import axios from 'axios'
import Loading from '@/components/Loading.vue';
import VueHorizontal from 'vue-horizontal';



export default {
  name: 'HomeView',
  data(){
    return{
      latestProducts: [],
      imageLoaded: false,
      stored_plans: [],
      cart: {
        items: []
      },
      /*
      parallax: 1,
        parallaxFixedContent: false,
        autoPlaying: true,
        slides: [
            {
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1694944771/cna5j6wc102jzvobgtju.jpg'
            },
            {
            
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1694944823/alrgua0qeqqwcugrcahf.jpg'
            //image: 'https://res.cloudinary.com/celestialmk/image/upload/v1688116255/b26lflew9krkj82mfoz1.jpg'
            //image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478763/xxdcfayopd2tfxefvcrx.jpg'
            },
            {
                image:'https://res.cloudinary.com/celestialmk/image/upload/v1694944789/zkrhhctztjc8pevt8v1x.jpg'
            },
            {
              image:'https://res.cloudinary.com/celestialmk/image/upload/v1694944842/sn6jsok2tjgqviaqkgks.png'
            }
      
  ]
  */
    }
  },
  components: {
    ProductComponent,
    Loading,
    PlanFilter,
    SearchFilter,
    VueHorizontal,
},
mounted(){
    //this.getLatestProducts()
    this.cart = this.$store.state.cart
    this.stored_plans = this.$store.state.stored_plans
    document.title = 'Home | Tofali Plans'
  },
  methods: {
    async getLatestProducts() {
      this.$store.commit('setIsLoading', true)

      await axios
        .get('/api/v1/plans/latest-plans/')
        .then(response => {
          this.latestProducts = response.data
        })
        .catch(error => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    },
    onImageLoad(){
      this.imageLoaded = true
    }
  },
  computed: {
      cartTotalLength() {
          let totalLength = 0

          for (let i = 0; i < this.cart.items.length; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>


<style scoped>
.item {
  background: #f3f3f3;
  padding: 16px 24px;
  margin-right: 24px;
  border-radius: 4px;
}

</style>
