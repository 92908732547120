<template>
    <div class="is-flex is-justify-content-center is-align-items-center" style=" height: 100vh;">
      <div class="has-text-centered">
        <h1 class="is-size-1 has-text-weight-bold has-text-primary">404</h1>
        <p class="is-size-5 has-text-weight-medium"> <span class="has-text-danger">Opps!</span> Page not found.</p>
        <p class="is-size-6 mb-2">
          The page you’re looking for doesn’t exist.
        </p>
        <router-link to="/" class="button is-primary">Go to Home Page</router-link>
      </div>
    </div>
</template>

<script>
export default {
    name: 'PathNotFound'
}
</script>

<style scoped>
.is-success {
    background-color:#004100;
}
</style>