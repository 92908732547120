<template>
    <div v-if="imageLoaded" class="transparent-box is-overlay is-hidden-touch">
        <div class="has-text-centered">
           <p class="pb-2 pt-2">Bedrooms</p>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_1 ? 'is-success' : '']" @click="selected_bd_1 = !selected_bd_1">1</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_2 ? 'is-success' : '']" @click="selected_bd_2 = !selected_bd_2">2</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_3 ? 'is-success' : '']" @click="selected_bd_3 = !selected_bd_3">3</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_4 ? 'is-success' : '']" @click="selected_bd_4 = !selected_bd_4">4</button>
           <button class="button mr-1 is-circular" :class="[selected_bd_5 ? 'is-success' : '']" @click="selected_bd_5 = !selected_bd_4">5+</button>
       </div>
       <div>
           <p class=" pb-2 pt-2">Bathrooms</p>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_1 ? 'is-success' : '']" @click="selected_ba_1 = !selected_ba_1">1</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_2 ? 'is-success' : '']" @click="selected_ba_2 = !selected_ba_2">2</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_3 ? 'is-success' : '']" @click="selected_ba_3 = !selected_ba_3">3</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_4 ? 'is-success' : '']" @click="selected_ba_4 = !selected_ba_4">4</button>
           <button class="button ml-0 mr-1 is-circular" :class="[selected_ba_5 ? 'is-success' : '']" @click="selected_ba_5 = !selected_ba_5">5+</button>
       </div>
       <div>
           <p class="pb-2 pt-2">Stories</p>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_st_1 ? 'is-success' : '']" @click="selected_st_1 = !selected_st_1">1</button>
           <button id="bed_button" class="button mr-1 is-circular" :class="[selected_st_2 ? 'is-success' : '']" @click="selected_st_2 = !selected_st_2">2</button>
           <button class="button mr-1 is-circular" :class="[selected_st_3 ? 'is-success' : '']" @click="selected_st_3 = !selected_st_3">3+</button>
       </div>
       <div>
        <p class="pb-2 pt-2">Sq metres</p>
        <input type="text" class="input is-small" v-model="floor_area" style="width: 100px; border-radius: 10px" name="" id="">
       </div>
       <div class="mt-2">
            <button class="button is-fullwidth is-success is-uppercase" @click="searchPlans()" > Search Plans</button>
       </div>
    </div>

    <div class="section pt-2 pb-2">
        <div class="box  is-hidden-desktop">
            <h2 class="is-size-4 has-text-centered">Filter</h2>
            <div class="has-text-centered">
                <p class="pb-2 pt-2">Bedrooms</p>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_1 ? 'is-success' : '']" @click="selected_bd_1 = !selected_bd_1">1</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_2 ? 'is-success' : '']" @click="selected_bd_2 = !selected_bd_2">2</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_3 ? 'is-success' : '']" @click="selected_bd_3 = !selected_bd_3">3</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_4 ? 'is-success' : '']" @click="selected_bd_4 = !selected_bd_4">4</button>
                <button class="button mr-1 is-circular" :class="[selected_bd_5 ? 'is-success' : '']" @click="selected_bd_5 = !selected_bd_4">5+</button>
            </div>
            <div>
                <p class=" pb-2 pt-2">Bathrooms</p>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_1 ? 'is-success' : '']" @click="selected_ba_1 = !selected_ba_1">1</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_2 ? 'is-success' : '']" @click="selected_ba_2 = !selected_ba_2">2</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_3 ? 'is-success' : '']" @click="selected_ba_3 = !selected_ba_3">3</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_4 ? 'is-success' : '']" @click="selected_ba_4 = !selected_ba_4">4</button>
                <button class="button ml-0 mr-1 is-circular" :class="[selected_ba_5 ? 'is-success' : '']" @click="selected_ba_5 = !selected_ba_5">5+</button>
            </div>
            <div>
                <p class="pb-2 pt-2">Stories</p>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_st_1 ? 'is-success' : '']" @click="selected_st_1 = !selected_st_1">1</button>
                <button id="bed_button" class="button mr-1 is-circular" :class="[selected_st_2 ? 'is-success' : '']" @click="selected_st_2 = !selected_st_2">2</button>
                <button class="button mr-1 is-circular" :class="[selected_st_3 ? 'is-success' : '']" @click="selected_st_3 = !selected_st_3">3+</button>
            </div>
            <div>
                <p class="pb-2 pt-2">Sq metres</p>
                <input type="text" class="input is-small" v-model="floor_area" style="width: 100px; border-radius: 10px" name="" id="">
            </div>
            <div class="mt-2">
                    <button class="button is-fullwidth is-success is-uppercase" @click="searchPlans()" > Search Plans</button>
            </div>                    
        </div>

    </div>
</template>

<script>
import axios from 'axios'
    export default {
        name: 'SearchFilter',
        props: {
            imageLoaded: ''
        },
        data(){
            return{
                selected_bd_1: false,
                selected_bd_2: false,
                selected_bd_3: false,
                selected_bd_4: false,
                selected_bd_5: false,
                selected_ba_1: false,
                selected_ba_2: false,
                selected_ba_3: false,
                selected_ba_4: false,
                selected_ba_5: false,
                selected_st_1: false,
                selected_st_2: false,
                selected_st_3: false,
                
                floor_area: '',
                bathroom_list:[],
                bedroom_list:[],
                storey_list:[],

                 //Pagination
                currentPage: 1,
                showPrevButton: false,
                showNextButton: false,
            }
        },
        methods: {
             async searchPlans(){
                let bathroomSet = new Set();
                let bedroomSet = new Set();
                let storeySet = new Set();
                //this.fetch_products.map(plan => plan.tags.forEach(tag => tagSet.add(tag)));
                //this.tags = Array.from(tagSet)

                //Bathroom selections
                this.selected_ba_1 ? bathroomSet.add(1) : bathroomSet.delete(1)
                this.selected_ba_2 ? bathroomSet.add(2) : bathroomSet.delete(2)
                this.selected_ba_3 ? bathroomSet.add(3) : bathroomSet.delete(3)
                this.selected_ba_4 ? bathroomSet.add(4) : bathroomSet.delete(4)
                this.selected_ba_5 ? bathroomSet.add(5) : bathroomSet.delete(5)

                //Bedroom selections
                this.selected_bd_1 ? bedroomSet.add(1) : bedroomSet.delete(1)
                this.selected_bd_2 ? bedroomSet.add(2) : bedroomSet.delete(2)
                this.selected_bd_3 ? bedroomSet.add(3) : bedroomSet.delete(3)
                this.selected_bd_4 ? bedroomSet.add(4) : bedroomSet.delete(4)
                this.selected_bd_5 ? bedroomSet.add(5) : bedroomSet.delete(5)
                
                //Stories selections
                this.selected_st_1 ? storeySet.add(1) : storeySet.delete(1)
                this.selected_st_2 ? storeySet.add(2) : storeySet.delete(2)
                this.selected_st_3 ? storeySet.add(3) : storeySet.delete(3)
                
                this.$store.commit('setIsLoading', true)
            
                this.bathroom_list = Array.from(bathroomSet)
                this.bedroom_list = Array.from(bedroomSet)
                this.storey_list = Array.from(storeySet)
                
                this.$store.commit('setIsLoading', true)
            
                await axios
                    .get(`/api/v1/plans/?page=${this.currentPage}&bath_rooms__in=${this.bathroom_list}&bed_rooms__in=${this.bedroom_list}&floors__in=${this.storey_list}&search=${this.floor_area}`)
                    .then(response => {
                        this.$store.commit('setSearchPlanResults', response.data.results)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
                console.log(this.$store.state.search_plan_results)

                this.$router.push('/plans-sort')
            } 
        }
    }
</script>

<style lang="scss" scoped>


.transparent-box {
      background-color: rgba(255, 255, 255, 0.5); /* Adjust the last value for transparency (0 to 1) */
      padding: 20px;
      border-radius: 10px;
      margin: 20px;
      margin-left: 100px;
      margin-top: 100px;
      max-width: 300px;
      max-height: 400px; /* Adjust the width as needed */
    }

.is-circular { border-radius: 50%; }


</style>