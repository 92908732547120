<template>
    <div class="section pt-1 pb-3">
        <div class="columns">
            <div class="column is-4">
                <form method="get" action="/search">
                    <input class="input is-primary" type="text" placeholder="Search Plans" name="query">
                </form>
            </div>
            <div class="column">
                <router-link to="/cart">
                    <span class="material-symbols-outlined is-size-2">shopping_cart</span><span>{{ cartTotalLength }}</span>
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Layout',
        data(){
            return{
                cart: {
                    items: []
                }
            }
        },
        computed: {
            cartTotalLength() {
            let totalLength = 0

            for (let i = 0; i < this.cart.items.length; i++) {
                totalLength += this.cart.items[i].quantity
            }
            return totalLength
            }
        }
        
    }
</script>

<style lang="scss" scoped>
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

</style>