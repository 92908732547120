<template>
    <div class="section mt-4" style="background-color: #F8F8F8" >
        <div class="pb-2 has-text-left" v-if="this.$store.state.search_plan_results.length > 0">
            <p class="is-size-4 has-text-weight-bold">{{ this.$store.state.search_plan_results.length }} results</p>
        </div>
        <div class="pb-2 has-text-left" v-else>
            <p class="is-size-4 has-text-weight-bold">{{ this.search_products.length }} results</p>
        </div>
        <div class="columns is-mobile">
            <div class="column" v-if="showPrevButton">
                <button class="button" @click="loadPrev()">Prev</button>
            </div>
            <div class="column" v-if="showNextButton">
                <button class="button" @click="loadNext()">Next</button>
            </div>
        </div>
        <div class="columns">
        <div class="column is-3">
            <div class="box" style="background-color: #EBEBEB">
                <h2 class="is-size-4 has-text-left">Filter</h2>
                <hr style="margin-top: 2px; background-color: #B2B2B2;">
                <div class="has-text-left">
                    <p class="pb-2 pt-2">BEDS</p>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_1 ? 'is-success' : '']" @click="selected_bd_1 = !selected_bd_1, searchPlans()">1</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_2 ? 'is-success' : '']" @click="selected_bd_2 = !selected_bd_2, searchPlans()">2</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_3 ? 'is-success' : '']" @click="selected_bd_3 = !selected_bd_3, searchPlans()">3</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_bd_4 ? 'is-success' : '']" @click="selected_bd_4 = !selected_bd_4, searchPlans()">4</button>
                    <button class="button mr-1 is-circular" :class="[selected_bd_5 ? 'is-success' : '']" @click="selected_bd_5 = !selected_bd_4, searchPlans()">5+</button>
                </div>
                <div class="has-text-left">
                    <p class=" pb-2 pt-2">BATHS</p>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_1 ? 'is-success' : '']" @click="selected_ba_1 = !selected_ba_1, searchPlans()">1</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_2 ? 'is-success' : '']" @click="selected_ba_2 = !selected_ba_2, searchPlans()">2</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_3 ? 'is-success' : '']" @click="selected_ba_3 = !selected_ba_3, searchPlans()">3</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_ba_4 ? 'is-success' : '']" @click="selected_ba_4 = !selected_ba_4, searchPlans()">4</button>
                    <button class="button ml-0 mr-1 is-circular" :class="[selected_ba_5 ? 'is-success' : '']" @click="selected_ba_5 = !selected_ba_5, searchPlans()">5+</button>
                </div>
                <div class="has-text-left">
                    <p class="pb-2 pt-2">STORIES</p>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_st_1 ? 'is-success' : '']" @click="selected_st_1 = !selected_st_1, searchPlans()">1</button>
                    <button id="bed_button" class="button mr-1 is-circular" :class="[selected_st_2 ? 'is-success' : '']" @click="selected_st_2 = !selected_st_2, searchPlans()">2</button>
                    <button class="button mr-1 is-circular" :class="[selected_st_3 ? 'is-success' : '']" @click="selected_st_3 = !selected_st_3, searchPlans()">3+</button>
                </div>
                <div class="has-text-left">
                    <p class="pb-2 pt-2">Sq metres</p>
                    <input type="text" class="input  mr-2" v-model="floor_area_min" style="width: 100px; border-radius: 10px" name="" id="" @change="searchPlans()" placeholder="Min">  
                    <input type="text" class="input " v-model="floor_area_max" style="width: 100px; border-radius: 10px" name="" id="" @change="searchPlans()" placeholder="Max"> 
                </div>
                <div class="has-text-left">
                    <p class="pb-2 pt-2">Plan #</p>
                    <input type="text" class="input " name="" id="" v-model="plan_id" placeholder="Enter Plan #">
                </div>

                <div class="has-text-left">
                <h2 class="is-size-4 mt-4">Filter by Features</h2>
                <hr style="margin-top: 2px; background-color: #B2B2B2;">

                <p class="pb-2 pt-2 has-text-weight-bold">ARCHITECTURAL STYLES</p>
                <div class="pt-2">
                    <router-link to="/bungalows">
                        <button class="button is-primary is-fullwidth is-small">Bungalow</button>
                    </router-link>
                </div>
                <div class="pt-2">
                    <router-link to="/villas">
                        <button class="button is-primary is-fullwidth is-small">Modern</button>
                    </router-link>
                </div>
                <div class="pt-2">
                    <router-link to="/mansions">
                        <button class="button is-primary is-fullwidth is-small">Mansion</button>
                    </router-link>
                </div>
                <div class="pt-2">
                    <router-link to="/apartments">
                        <button class="button is-primary is-fullwidth is-small">Apartment</button>
                    </router-link>
                </div>
                <div class="pt-2">
                    <button class="button is-primary is-fullwidth is-small" @click="setSearchPlansAll()">All</button>
                </div>

                <!--
                    <div v-for="tag in tags" :key="tag" class="pt-2">
                        <button class="button is-primary is-fullwidth is-small" @click="this.selectedTag = tag, searchPlans()">{{ tag }}</button>
                    </div>
                    <div class="pt-2">
                        <button class="button is-primary is-fullwidth is-small" @click="this.selectedTag = ''">All</button>
                    </div>
                -->

                <!--
                    <p class="pb-2 pt-2 has-text-weight-bold">PLAN SET PRICING</p>
                    <div class="is-size-7">
                        <p>UGX 0 - UGX 1,900,000</p>
                        <p>UGX 2,000,000 - UGX 2,900,000</p>
                        <p>UGX 3,000,000 - UGX 3,900,000</p>
                        <p>UGX 4,000,000 - UGX 4,900,000</p>
                    </div>
                -->
                </div>
                
            </div>
        </div>

        <div class="columns is-8 is-multiline" v-if="this.$store.state.search_plan_results.length > 0">
                <ProductComponent 
                    v-for="product in this.$store.state.search_plan_results"
                    v-bind:key="product.id"
                    v-bind:product="product" />
        </div>
        <div v-else class="columns is-8 is-multiline">
                <ProductComponent 
                    v-for="product in search_products"
                    v-bind:key="product.id"
                    v-bind:product="product" />
        </div>
        </div>
    </div>
    
</template>

<script>
import ProductComponent from '@/components/ProductComponent.vue';
import Loading from '@/components/Loading.vue';
import axios from 'axios';

    export default {
        name: 'PlanSort',
        data(){
            return{
                item_selected: '',
                query: '',
                bed_rooms: [1,2,3],
                bath_rooms: [1,2,3],
                floors: [1,2,3],
                search_products: '',
                fetch_products:[],
                is_active: true,

                //Pagination
                currentPage: 1,
                showPrevButton: false,
                showNextButton: false,

                //Tags
                tags: [],
                selectedTag:'',
                filteredPlans:[],

                //Search Plans
                selected_bd_1: false,
                selected_bd_2: false,
                selected_bd_3: false,
                selected_bd_4: false,
                selected_bd_5: false,
                selected_ba_1: false,
                selected_ba_2: false,
                selected_ba_3: false,
                selected_ba_4: false,
                selected_ba_5: false,
                selected_st_1: false,
                selected_st_2: false,
                selected_st_3: false,

                floor_area:'',
                tags_value: this.floor_area + ' '+ this.selectedTag, 
                floor_area_min: '',
                floor_area_max: '',
                plan_id: '',
                bathroom_list:[],
                bedroom_list:[],
                storey_list:[],
                
            }
        },
        components: {
            ProductComponent,
            Loading,
        },
        mounted(){
            this.GetPlans(),
            this.FetchPlans()
        },
        methods:{
            setSearchPlansAll(){
                this.$store.commit('setSearchPlanResults', this.search_products)
            },
            setFilterPlans(){
                this.filteredPlans = this.$store.state.search_plan_results.filter(plan => {
                        return this.selectedTag == '' || plan.tags.includes(this.selectedTag)
                    })
            },
            setTags(){
                let tagSet = new Set();
                this.fetch_products.map(plan => plan.tags.forEach(tag => tagSet.add(tag)));
                this.tags = Array.from(tagSet)
            },

            async GetPlans(){
                this.$store.commit('setIsLoading', true)
                if(this.$store.state.search_plan_results != {}){
                    await axios
                        .get(`/api/v1/plans?page=${this.currentPage}`)
                        .then(response => {
                            this.showPrevButton = false
                            this.showPrevButton = false
                            if(response.data.next){
                                this.showNextButton = true   
                            }
                            if(response.data.previous){
                                this.showPrevButton = true
                            }
                            this.search_products = response.data.results

                            console.log(this.search_products)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                    
                    }
                this.$store.commit('setIsLoading', false)
            },
            loadNext(){
                this.currentPage += 1
                this.GetPlans()
            },
            loadPrev(){
                this.currentPage -= 1
                this.GetPlans()
            },
            async FetchPlans(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get(`/api/v1/plans/?page=${this.currentPage}`)
                    .then(response => {
                        this.fetch_products = response.data.results
                        this.showPrevButton = false
                        this.showNextButton = false
                        if(response.data.next){
                            this.showNextButton = true   
                        }
                        if(response.data.previous){
                            this.showPrevButton = true
                        }

                        console.log(this.fetch_products)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                
                this.setTags()
                this.$store.commit('setIsLoading', false)
            },


            async searchPlans(){
                let bathroomSet = new Set();
                let bedroomSet = new Set();
                let storeySet = new Set();
                //this.fetch_products.map(plan => plan.tags.forEach(tag => tagSet.add(tag)));
                //this.tags = Array.from(tagSet)

                //Bathroom selections
                this.selected_ba_1 ? bathroomSet.add(1) : bathroomSet.delete(1)
                this.selected_ba_2 ? bathroomSet.add(2) : bathroomSet.delete(2)
                this.selected_ba_3 ? bathroomSet.add(3) : bathroomSet.delete(3)
                this.selected_ba_4 ? bathroomSet.add(4) : bathroomSet.delete(4)
                this.selected_ba_5 ? bathroomSet.add(5) : bathroomSet.delete(5)

                //Bedroom selections
                this.selected_bd_1 ? bedroomSet.add(1) : bedroomSet.delete(1)
                this.selected_bd_2 ? bedroomSet.add(2) : bedroomSet.delete(2)
                this.selected_bd_3 ? bedroomSet.add(3) : bedroomSet.delete(3)
                this.selected_bd_4 ? bedroomSet.add(4) : bedroomSet.delete(4)
                this.selected_bd_5 ? bedroomSet.add(5) : bedroomSet.delete(5)
                
                //Stories selections
                this.selected_st_1 ? storeySet.add(1) : storeySet.delete(1)
                this.selected_st_2 ? storeySet.add(2) : storeySet.delete(2)
                this.selected_st_3 ? storeySet.add(3) : storeySet.delete(3)
                
                this.$store.commit('setIsLoading', true)
            
                this.bathroom_list = Array.from(bathroomSet)
                this.bedroom_list = Array.from(bedroomSet)
                this.storey_list = Array.from(storeySet)
                
                await axios
                    .get(`/api/v1/plans/?page=${this.currentPage}&bath_rooms__in=${this.bathroom_list}&bed_rooms__in=${this.bedroom_list}&floors__in=${this.storey_list}&search=${this.floor_area}`)
                    .then(response => {
                        this.showPrevButton = false
                        this.showNextButton = false
                        if(response.data.next){
                            this.showNextButton = true   
                        }
                        if(response.data.previous){
                            this.showPrevButton = true
                        }

                        this.$store.commit('setSearchPlanResults', response.data.results)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
                console.log(this.$store.state.search_plan_results)
            },

        }
    }
</script>

<style lang="scss" scoped>

</style>