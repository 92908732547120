<template>
    <div class="column is-12">
      <h2 class="title has-text-centered">Building Plans</h2>
  </div>
    <div class="columns section">
        <div class="column is-3 box">      
            <p class="block-image skeleton"></p>
            <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            <p class="skeleton skeleton-text skeleton-text__body"></p>      
        </div>
        <div class="column is-3 box">
            <p class="block-image skeleton"></p>
            <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            <p class="skeleton skeleton-text skeleton-text__body"></p>
        </div>
        <div class="column is-3 box">
            <p class="block-image skeleton"></p>
            <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            <p class="skeleton skeleton-text skeleton-text__body"></p>
        </div>
        <div class="column is-3 box">
            <p class="block-image skeleton"></p>
            <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            <p class="skeleton skeleton-text skeleton-text__body"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading'
    }
</script>

<style scoped>
.block-image{
  width: 100%;
  height: 100px;
  padding: 5px;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

</style>