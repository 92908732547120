<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <router-link to="/" class="navbar-item">
    
        <img src="/Tofali-word-logo.png" width="112" height="28">
    </router-link>

    <a role="button" class="navbar-burger" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNav }">
    <div class="navbar-start">
      <a class="navbar-item">
      <router-link to="/">
          Home
        </router-link>
      </a>

      <a class="navbar-item">
      <router-link to="/collections">
          Collections
        </router-link>
      </a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          Styles
        </a>

        <div class="navbar-dropdown">
          <router-link to="/bungalows">
            Bungalow
          </router-link>
          <hr class="navbar-divider">
          <router-link to="/apartments">
            Apartment
          </router-link>
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons" v-if="!$store.state.isAuthenticated">
          <router-link to="/sign-up" class="button is-primary">
              <strong>Sign up</strong>
          </router-link>
          <router-link to="/log-in" class="button is-light">
              Log in
          </router-link>
        </div>
        <div v-else>
          <div class="buttons">
            <a class="button is-light" @click="logout()">Log Out</a>
            <a class="button is-light">Logged In</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Navbar',
    data(){
        return{
            showNav: false
        }
    },
    methods:{
      logout() {
        axios.defaults.headers.common["Authorization"] = "";

        
        //localStorage.removeItem("userid")

        this.$store.commit("removeAccess");

        this.$router.push("/");
    },
  }
}
</script>

<style lang="scss" scoped>

</style>