import { createStore } from 'vuex'
import axios from 'axios'


export default createStore({
  state: {
    cart: {
      items: [],
    },
    isAuthenticated: false,
    isLoading: false,
    username:'',
    isAuthenticated: false,
    cartTotalLength:'',
  
    access: '',
    refresh: '',
    favorites: [],

    //Stored Plans
    stored_plans:[],

    //Filter system
    search_plan_results: []

  },
  getters: {
  },
  mutations: {
    initializeStore(state){
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('stored_plans')){
        state.stored_plans = JSON.parse(localStorage.getItem('stored_plans'))
      } else {
        localStorage.setItem('stored_plans', JSON.stringify(state.stored_plans))
      }



      if(localStorage.getItem('refresh')){
        state.refresh = localStorage.getItem('refresh')
      }else{
        state.refresh = ''
      }
      if(localStorage.getItem('access')){
          state.access = localStorage.getItem('access')
          //state.refresh = localStorage.getItem('refresh')
          state.isAuthenticated = true
      }else{
          state.access = ''
          //state.refresh = ''
          state.isAuthenticated = false
      }
      if(localStorage.getItem('username')){
          state.username = localStorage.getItem('username')
          //state.isAuthenticated = true
      }else{
          //state.token = ''
          //state.isAuthenticated = false
      }
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length) {
      } else {
        state.cart.items.push(item)
      }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    addToStoredPlans(state, item){
      const exists = state.stored_plans.filter(i => i.product.id === item.product.id)
      if (exists.length){
      } else {
        state.stored_plans.push(item)
      }

      localStorage.setItem('stored_plans', JSON.stringify(state.stored_plans))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    clearCart(state){
      state.cart = { items: [] }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },

     //Authentication
    setAccess(state, access){
      state.access = access
      state.isAuthenticated = true
    },
    setRefresh(state, refresh){
      state.refresh = refresh
    },
    setUsername(state, username){
      state.username = username
      state.isAuthenticated = true
      localStorage.setItem('username', state.username)
    },
    removeAccess(state){
      state.access = ''
      state.isAuthenticated = false
    },
    setFavorites(state, favorites){
      state.favorites = favorites
    },
    setCartTotalLength(state, cartTotalLength){
      state.cartTotalLength = cartTotalLength
    },
    setSearchPlanResults(state, search_plan_results){
      state.search_plan_results = search_plan_results
    }
  },
  actions: {
    fetchFavorites(context){
      return axios.get('/api/v1/plans/favorites/')
          .then(response => {
            console.log(response.data)
            context.commit("setFavorites", response.data)
            console.log(this.state.favorites)
          })
          .catch((err) => console.error(err));
    },
  },
  modules: {
  }
})
