<template>
    <footer class="footer">
        <div class="content has-text-centered">
            <div class="columns">
                <div class="column is-one-third is-uppercase">
                    Terms and Conditions
                </div>
                <div class="column is-one-third is-upppercase">
                    Contact US
                </div>
                <div class="column is-one-third is-uppercase">
                    privacy Policy
                </div>
            </div>
            <p>
            <strong>Tofali Plans</strong> by <a href="https://ecoconcrete.co.ug">Eco Concrete Ltd</a>. Sept 15 Version
            </p>
        </div>
        
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        components: {
           // VueWhatsappWidget
        }
    }
</script>

<style lang="scss" scoped>

</style>