<template>
    <div class="column is-4" @click="addToStoredPlans()">
        <div class="box">
            <router-link v-bind:to="product.get_absolute_url" class="has-text-grey">

                <figure class="image">
                    <img v-bind:src="product.get_thumbnail">
                </figure>
                
                <h3 class="is-size-4 has-text-grey has-text-left has-text-weight-medium">{{ product.name }}</h3>
                <div class="columns is-mobile">
                    <div class="column is-3">
                        <p class="is-size-6 has-text-grey has-text-weight-medium">{{ product.floor_area }}</p>
                        <h3 class="is-size-7">Sq. M</h3>
                        
                    </div>
                    <div class="column is-2">
                        <p class="is-size-6 has-text-grey has-text-weight-medium">{{ product.bed_rooms }}</p>
                        <h3 class="is-size-7">Bed</h3>
                    </div>
                    <div class="column is-2">
                        <p class="is-size-6 has-text-grey has-text-weight-medium">{{ product.bath_rooms }}</p>
                        <h3 class="is-size-7">Bath</h3>
                    </div>
                    <div class="column is-3">
                        <p class="is-size-6 has-text-grey has-text-weight-medium">{{ product.floors }}</p>
                        <h3 class="is-size-7">Floors</h3>
                    </div>
                    <div class="column is-2">
                        <span @click="addToFavorite()"><i class="fa fa-heart"></i></span>
                    </div>

                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
export default {
    name: 'ProductComponent',
    props: {
        product: Object
    },
    methods:{
        addToFavorite(){
            const data = {
                product: this.product.id
            }
            axios
                .post('/api/v1/plans/add-to-favorites/', data)
                .then(response => {
                    toast({
                            message: 'Plan added to Favorites!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 4000,
                            position: 'bottom-right',
                        })        
                })
                .catch(error => {
                    //this.errors.push('Something went wrong. Please try again')
                    toast({
                            message: 'Plan not added to Favorites, Check Internet connection!',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 4000,
                            position: 'bottom-right',
                        })   
                    console.log(error)
                })

                this.$store.commit('setIsLoading', false)
        },
        addToStoredPlans(){
            const item = {
                product: this.product
            }

            this.$store.commit('addToStoredPlans', item)
        },
        
    }
}
</script>

<style scoped>
  .image {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
</style>